<template lang="pug">

div
  EmbedCe(v-if='ceUrl', :url='ceUrl')
  p(v-if='!ceUrl') Not found
  
</template>
  
<script>

  export default {
    components: {
      EmbedCe: () => import('@/components/EmbedCe.vue'),
    },
    props: {
      taskAttribute: Object,
      albumId: String,
    },
    computed: {
      ceUrl() {
        if (!this.albumId) return ''
        return `Album/${this.albumId}/`
      }
    }
  }

</script>
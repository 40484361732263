var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.ceUrl ? _c("EmbedCe", { attrs: { url: _vm.ceUrl } }) : _vm._e(),
      !_vm.ceUrl ? _c("p", [_vm._v("Not found")]) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }